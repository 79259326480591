import { render, staticRenderFns } from "./responsibility.vue?vue&type=template&id=15f4f94b&scoped=true&"
import script from "./responsibility.vue?vue&type=script&lang=js&"
export * from "./responsibility.vue?vue&type=script&lang=js&"
import style0 from "./responsibility.vue?vue&type=style&index=0&id=15f4f94b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15f4f94b",
  null
  
)

export default component.exports